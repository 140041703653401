<template>
    <div class="Client">
        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper container-xxl p-0">

                <banner title="Income Tax Return Filing" :breadcrumb="[
                    { label: 'Category ', url: 'http://localhost:8080/retailer/client' },
                    { label: 'Company Public ' },
                
                ]">
                </banner>
                <div class="row" style="margin-top: -45px;">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding:5px 5px 5px 5px ">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12 text-start">
                                        <h4><strong style="color:black;">Step-2 : Other Informations</strong></h4>
                                        </div>
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12 col-12 text-end"> </div>
                                </div>
                                <div class="progress ">
                                    <div style="background-color:#f21300;width: 20%" class="progress-bar"
                                        role="progressbar" aria-valuenow="40" aria-valuemin="40" aria-valuemax="100">20%
                                    </div>
                                </div>

                                <Form @submit="itrfillingservice" :validation-schema="schema" v-slot="{ errors }">
                                    <div class="row mb-2">
                                        <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">
                                            <!--  -->
                                            <label>Pan </label>
                                             <Field @change="findRecordeByPanId" type="tel" id="pan" maxlength="10" v-model="panNo" class="form-control" placeholder="Pan" style="height:33px;text-transform: uppercase;" name="panNo" />
                                           <div> <small v-if="errors.panNo" >{{ errors.panNo }}</small></div>
                                            <div><small @click="openModal" type="button" data-bs-toggle="modal" data-bs-target="#clientModal">create New Client</small></div>
                                        </div>
                                             <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">
                                                <label>Business Name </label>
                                                <Field as="select" style="height:33px;text-transform: uppercase;" v-model="business_id"
                                                    class="form-select" aria-label="Default select example" name="businessName">
                                                    <option selected>Open this select menu</option>
                                                    <option :value="bus.id" v-for="(bus , index) in allBusiness" :key="index">
                                                        <span v-if="bus">{{bus.name}}</span>
                                                    </option>

                                                </Field>
                                                <div> <small v-if="errors.businessName" >{{ errors.businessName }}</small></div>
                                                <small @click="openBusinessModal" type="button" data-bs-toggle="modal"
                                                    data-bs-target="#createBussiness">Create New Bussiness</small>
                                            </div>

                                            <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">
                                        <label>First Name </label>
                                        <input type="tel" class="form-control" v-model="form.fname" placeholder="Name "
                                            style="height:33px ; text-transform: uppercase;">
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">
                                        <label>Last Name </label>
                                        <input type="tel" class="form-control" v-model="form.lname"
                                            placeholder="Last Name " style="height:33px; text-transform: uppercase; ">
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">

                                        <label>Address </label>
                                        <input type="text" class="form-control" v-model="form.address"
                                            placeholder=" Address" style="height:33px ; text-transform: uppercase;">

                                    </div>

                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">

                                        <label>Phone </label>
                                        <input type="text" v-model="form.phone" maxlength="10" class="form-control"
                                            placeholder="Phone number " style="height:33px; text-transform: uppercase; ">

                                    </div>
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">

                                        <label>Alt Phone Number </label>
                                        <input v-model="form.alternativePhone" type="text" maxlength="6"
                                            class="form-control" placeholder="Alt Phone Number " style="height:33px ; text-transform: uppercase;">

                                    </div>

                                    <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding:5px 5px 5px 5px ">

                                        <label>Email </label>
                                        <input v-model="form.email" type="email" class="form-control"
                                            placeholder="Email Address" style="height:33px ">

                                    </div>
                                    <div class="col-xl-12 col-md-12 col-lg-12 col-12" style="padding:5px 5px 5px 5px ">

                                        <label>Password </label>
                                        <input v-model="form.itrPassword" type="text" class="form-control"
                                            placeholder="Password" style="height:33px ">

                                    </div>


                                    </div>
                                    <h3 class="text-start">Charges : <span>{{ serviceCharge}}₹</span></h3>
                                    <div class="row">
                                        <div class="col">
                                            <button @click="openModal" type="button"
                                                    class="btn btn-sm bg-success text-white" data-bs-toggle="modal"
                                                    data-bs-target="#clientModal">New Client</button>
                                        </div>
                                        <div class="col">
                                            <button
                                            @click="openBusinessModal" type="button"
                                            class="btn btn-sm bg-success text-white" data-bs-toggle="modal"
                                            data-bs-target="#createBussiness">New Business</button>
                                        </div>
                                        <div class="col text-end">
                                            <button type="submit" class="btn btn-sm text-white" style="background-color:#00364f"><strong>Confirm</strong></button>
                                     
                                        </div>

                                    </div>
                                       
                                </Form>
                             

                            </div>
                        </div>

                     
                    </div>


                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding:5px 5px 5px 5px ">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading">FAQ's</h2>
                                        <div class="accordion accordion-margin accordion-border" id="accordionMargin">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginOne">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginOne"
                                                        aria-expanded="false" aria-controls="accordionMarginOne">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Salary Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginOne" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginOne"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Pastry pudding cookie toffee bonbon jujubes jujubes
                                                        powder topping. Jelly beans gummi bears sweet roll
                                                        bonbon muffin liquorice. Wafer lollipop sesame snaps.
                                                        Brownie macaroon cookie muffin cupcake candy
                                                        caramels tiramisu. Oat cake chocolate cake sweet jelly-o
                                                        brownie biscuit marzipan. Jujubes donut
                                                        marzipan chocolate bar. Jujubes sugar plum jelly beans
                                                        tiramisu icing cheesecake.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFour">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFour"
                                                        aria-expanded="false" aria-controls="accordionMarginFour">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        is Business Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFour" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFour"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingMarginFive">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#accordionMarginFive"
                                                        aria-expanded="false" aria-controls="accordionMarginFive">
                                                        <i data-feather='plus' class="accordian-plus"></i> What
                                                        are Other Sources of Income?
                                                    </button>
                                                </h2>
                                                <div id="accordionMarginFive" class="accordion-collapse collapse"
                                                    aria-labelledby="headingMarginFive"
                                                    data-bs-parent="#accordionMargin">
                                                    <div class="accordion-body">
                                                        Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                        cake gummies chocolate cake. Marshmallow tart
                                                        croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                        drops. Cake bonbon bonbon gummi bears topping
                                                        jelly beans brownie jujubes muffin. Donut croissant
                                                        jelly-o cake marzipan. Liquorice marzipan cookie
                                                        wafer tootsie roll. Tootsie roll sweet cupcake.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h2 class="heading">Videos</h2>
                                        <div class="video-player" id="plyr-video-player" style="margin: 10px;">
                                            <iframe src="https://www.youtube.com/embed/femaL6lGw1o" width="100%"
                                                height="360" allowfullscreen allow="autoplay"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </div>

        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title text h3-heading" id="staticBackdropLabel"><b>Add Client</b></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="(edit == 'false') ? addNewClient() : updateClient()" id="formId">
                        <div class="row">
                            <div class="col-xl-7 col-lg-7 col-md-7 col-12">

                                <div class="row">


                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label> Client Pan</label>
                                        <input type="tel" maxlength="10" v-model="form.pan" class="form-control"
                                            placeholder=" Pan" style="text-transform: uppercase" disabled>
                                    </div>

                                    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Enter Aadhar Number</label>
                                        <input type="tel" maxlength="12" v-model="form.aadharNo" class="form-control"
                                            placeholder="Mobile Number">
                                    </div> -->
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Mobile No.</label>
                                        <input type="tel" maxlength="10" v-model="form.phone" class="form-control"
                                            placeholder="Mobile Number">
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>First Name</label>
                                        <input type="text" v-model="form.fname" class="form-control"
                                            placeholder="First name" aria-label="First name"
                                            style="text-transform: uppercase">
                                    </div>


                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Last Name</label>
                                        <input type="text" v-model="form.lname" class="form-control"
                                            placeholder="Last name" aria-label="Last name"
                                            style="text-transform: uppercase">
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Date Of Birth</label>
                                        <input type="date" v-model="form.dob" class="form-control" placeholder="DOB"
                                            style="text-transform: uppercase">

                                    </div>


                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Gender</label>
                                        <select id="inputState" v-model="form.gender" class="form-select"
                                            style="text-transform: uppercase">

                                            <option>Male </option>
                                            <option>FeMale </option>
                                            <option>Other </option>

                                        </select>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Email</label>
                                        <input type="email" class="form-control" v-model="form.email"
                                            placeholder="E-Mail" style="text-transform: uppercase">
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Alternate Number</label>
                                        <input type="number" class="form-control" v-model="form.alternativePhone"
                                            placeholder="Alternate Number">
                                    </div>



                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5 col-12 border-3 border-start">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Address line 1</label>
                                        <input type="text" class="form-control" v-model="form.address"
                                            placeholder="Address Line-1" style="text-transform: uppercase">
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>Address line 2</label>
                                        <input type="text" class="form-control" v-model="form.address2"
                                            placeholder="Address Line-2" style="text-transform: uppercase">
                                    </div>


                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px;">
                                        <label>State</label>
                                        <select id="inputState" v-model="form.state_id" class="form-select">

                                            <option v-for="(state, index) in states" :key="index" :value="state.id">
                                                {{ state.name }} </option>

                                        </select>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px;">

                                        <label>City</label>
                                        <input type="text" v-model="form.city" class="form-control" id="inputCity"
                                            placeholder="City" style="text-transform: uppercase">
                                    </div>


                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px;">

                                        <label>Pin Code</label>
                                        <input type="number" maxlength="6" v-model="form.pin" min="1"
                                            class="form-control" id="inputZip" placeholder="Pin">
                                    </div>

                                </div>


                            </div>
                        </div>


                        <div class="modal-footer" style="margin-top:10px;">

                            <button type="submit" class="btn btn-sm text-white" style="background-color:#00364f; "><b>
                                    <font-awesome-icon icon="check" /> Confirm
                                </b> </button>

                            <button type="reset" class="btn btn-sm text-white" style="background-color:red; "><b>
                                    <font-awesome-icon icon="remove" />
                                </b> Reset</button>
                            <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
    <!-- end of the model -->

    <ModalClient ref="ModalClient" />

    <ModalBusiness ref="ModalBusiness" />
    <!-- <DocumentUpload ref="DocumentUpload" /> -->



</template>

<script setup>
import { Form, Field} from 'vee-validate';
const schema = {
  panNo: 'required|min:10|max:10',
  businessName: 'required',
  
}

</script>

<script>
  
import ModalClient from '../../../../components/retailer/comman/ClientModal.vue'
import ModalBusiness from '../../../../components/retailer/comman/BusinessModal.vue'
// import DocumentUpload from '../../../../components/retailer/comman/DocumentUpload.vue'
import Banner from '../../../../components/retailer/comman/Banner.vue'

// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
    name: 'CompanyPublic3',
    components: {
        Banner,
        ModalClient,
        ModalBusiness,
        // DocumentUpload
    },
    data() {
        return {
            clientservicedocument: [],
            allDoccs: [],
            uploadDocc_form: {
                client_service_id: null,
                service_document_id: null,
                alt: '',
                caption: '',
                serviceDocument: '',
                cwidth: '',
                cheight: '',
                cx: '',
                cy: '',
            },
            allBusiness: [],
            business_id: null,
            states: [],
            panNo: '',
            record: {},
            serviceCharge: '',
            charges: {},
           
            form:{
                photo: '',
                pan: '',
                fname: '',
                lanme: '',
                phone: '',
                email: '',
                dob: null,
                gender: null,
                address: null,
                address2: null,
                alternativePhone: null,
                city: null,
                fatherName: '',
                pin: null,
                state_id: null,
                code: '',
            },
            veryfied: 'false',
            exist: 'false',
            
        }
    },
    methods: {
         itrfillingservice() {
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            itr.business_id = this.business_id
            itr.client_id = this.form.id
            localStorage.setItem('itr', JSON.stringify(itr))
            this.$swal.fire({
                title: 'Are You Sure ?',
                // showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                // denyButtonText: `No`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.$axios
                .post('retailer/clientservice', itr, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                     res.data.clientService.id
                     itr = JSON.parse(localStorage.getItem('itr'))
                     itr.client_service_id = res.data.clientService.id
                     localStorage.setItem('itr', JSON.stringify(itr))
                     this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'The form was sent successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$router.push({ name: 'CompanyPublic4' })
                })
                 
                } 
            })
         
        },
        findRecordeByPanId() {
            this.$axios
                .get(`retailer/client?pan=${this.panNo}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    this.record = res.data.data.data[0]
                    if (res.data.data.data[0]) {
                        this.form = this.record
                        for (var i = 0; i < this.record.business_clients.length; i++) {
                            this.allBusiness.push(this.record.business_clients[i].business)
                        }
                        this.veryfied = 'false'
                    } else {
                         this.form.pan = this.panNo
                        this.$swal.fire({
                             icon: 'error',
                            title: '<h5>This Client Is Not Found Please Create First.</h5>',
                            showConfirmButton: true,

                        })



                    }

                })
            },
        redirectBack() {
             this.$router.push({ name: 'Itr' })
        },
    },
    created() {
        if (this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null) {
            this.$router.push({ name: 'Login' })
        } else {
         var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            if (itr.serviceCharge) {
                this.serviceCharge = itr.serviceCharge
                // alert(this.serviceCharge)
            }
        }

    },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    font-family: "Poppins", sans-serif;
}

.p-text {
    color: #fff;
    /* font-family: poppins, Sans-serif; */
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 100%;
   width: 100%; */
    font-family: "Poppins", sans-serif;
}

.h2-heading {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 767px) {
    .h2-heading {
        font-size: 22px;
        font-family: "Poppins", sans-serif;
    }
}

.heading {
    color: #00364f;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
}

.inner-card {
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
}

.heading-red {
    color: #f21300;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
}
</style>